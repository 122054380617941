<template>
  <div>
    <!-- <div class="mbxtit">
      <div class="w1400">首页&nbsp;&nbsp;&gt;&nbsp;&nbsp;<span>行情价格</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;{{nametop.groupName}}&nbsp;&nbsp;&gt;&nbsp;&nbsp;<a style="color: #25449a" href="">{{nametop.productName}}</a></div>
    </div> -->
    <DetailNavBar :title="nametop.productName" />
    <div class="w1400">
      <div class="fengleilist clearfix">
        <div class="flistleft">
          <div class="allbody clearfix">
            <div class="leftbody">
              <p><span>{{nametop.groupName}}</span><span>{{nametop.productName}}</span></p>
              <p><span>分类：{{nametop.parentName}}</span><span>市场：{{nametop.marketName}}</span></p>
              <p>备注：{{nametop.comment}}</p>
            </div>
            <div class="rightbody">
              <span>{{nametop.addDate}}</span>
              <span v-if="nametop.hprice&&nametop.lprice">{{nametop.hprice}}-{{nametop.lprice}}</span>
              <span v-else style="font-weight: normal;font-size: 18px">暂无报价</span>
              <span  v-bind:class="nametop.data1==1 ? '' : 'active'"  @click="focusOrCancelPro(nametop.data1)">{{nametop.data1==1 ? "取消关注" :"+加入关注"}}</span>
            </div>
          </div>
          <div class="tablebottom">
            <div class="timechose clearfix">
              <ul>
                <li @click="getLastWeek" :class="{'active':current==0}"><span>近一周</span></li>
                <li @click="getLastMonth" :class="{'active':current==1}"><span>近一月</span></li>
                <li @click="getLast3Month" :class="{'active':current==2}"><span>近三月</span></li>
              </ul>
              <div class="timeself">
                <DatePicker  v-model="timeall" format="yyyy-MM-dd" type="daterange" placement="bottom-end"  @on-change="mate_date_Change" placeholder="请选择日期" style="width: 250px;height: 34px;"></DatePicker>
              </div>
            </div>
            <ul class="pricesee clearfix">
              <li>最高价：<span style="color: #E83033;">{{hPriceSum}}</span></li>
              <li>最低价：<span style="color: #31AA24;">{{lPriceSum}}</span></li>
              <li>均价：<span>{{aPriceSum}}</span></li>
            </ul>
            <div class="line" id="myChart" :style="{width: '800px', height: '380px'}"></div>
            <div class="tablesj">
              <Table style="margin-top: 30px"  :columns="columns1" :data="data2" stripe :loading="loading"></Table>
              <Page :total="total" :current="page"  @on-change="changenext"  show-elevator style="margin:22px 0 180px 0;float: right"></Page>
            </div>
          </div>
        </div>
        <div class="flistright">
            <div class="righttop">
              <p>APP扫码下载</p>
              <img src="~assets/img/public/QRcode_app.png" style="width: 150px;height: 150px;margin: 0 auto;display: block"/>
              <span>随时随地 关注行情</span>
            </div>
          <div  class="rightbottom">
            <p>热门分析</p>
            <div @click.stop="handleImgClick($event)">
              <!--<vue-seamless-scroll :data="fgtjarrs" style="height:412px;overflow: hidden;border: 1px solid #E6EBF5;" :class-option="defaultOption">-->
                <ul class="tiaojiabody"  style="height:380px;overflow: hidden;border: 1px solid #E6EBF5;">
                  <li class="clearfix" v-for="(fgtjarr, index) in fgtjarrs" :id="fgtjarr.id" :data-page="fgtjarr.page" :data-subjectId="fgtjarr.subjectId" :data-marketId="fgtjarr.marketId" >
                    <span class="fl"></span>[{{fgtjarr.marketName}}]{{fgtjarr.title}}
                  </li>
                </ul>
              <!--</vue-seamless-scroll>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavHead from '@/components/content/mainTabbar/NavHead'
  import LogoBar from '@/components/content/mainTabbar/LogoBar'
  import {selectMarketPriceHistoryApi,selectMpHistoryChartApi,selectMpDetailApi,focusOrCancelProApi,getAuctionNewsApi} from 'network/home'
export default {
  name: 'Marketdetail',
  data() {
    return {
      userName:"",
      loading:true,
      productId:"",//产品id
      marketId:"",//市场id
      current:0,//默认近一周
      starttime:"",//开始时间
      endtime:"",//结束时间
      timeall:[],
      nametop:{
        addDate:"",
        comment:"",
        groupName:"",
        marketName:"",
        parentName:"",
        productName:"",
        data1:"",
        type:""
      },
      fgtjarrs:[
        {name:"钢厂报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"基地报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"钢厂报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"基地报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"钢厂报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"基地报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"钢厂报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"基地报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"钢厂报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"},
        {name:"基地报价",title:"3月18日唐山国义特钢马蹄...",time:"09.13 18:00"}
      ],//热门发布
      hPriceSum:0,
      lPriceSum:0,
      aPriceSum:0,
      axisarr: {
        leftarr:[],
        rightarr:[],
        data:[]
      },//柱状图数据
      columns1: [
        {
          title: '产品名称',
          key: 'productName',
          align: "center"
        },
        {
          title: '分类',
          key: 'parentName',
          align: "center"
        },
        {
          title: '价格',
          key: '',
          align: "center",
          render: function (h, params) {
              return h('div',
                [
                  h('span', {
                  }, params.row.lprice),
                  h('span', {
                  }, '-'),
                  h('span', {
                  }, params.row.hprice)
                ]
              );
          }
        },
        {
          title: '涨跌',
          key: 'priceChange',
          align: "center",
          render: function (h, params) {
            // if(self.token&&this.setVip==1){
            let text=params.row.priceChange.substring(0,1)
            let texts=params.row.priceChange.substring(1,params.row.priceChange.length)
            let num=params.row.priceChange.substring(1)
            console.log(num)
            var imgstyle;
            var textstyle;
            if(text=="涨"){
              imgstyle="up"
              textstyle="red"
            }else if(text=="跌"){
              imgstyle="down"
              textstyle="green"
            }
            if(num==0){
              imgstyle=""
              textstyle=""
              texts="平"
            }
            return h('div',
              [
                h('span', {
                  style: {
                  },
                  class: [
                    imgstyle
                  ],
                },""),
                h('span', {
                  style: {
                  },
                  class: [
                    textstyle
                  ],

                },texts)
              ]
            );
            // }else{
            //   return h('div',"****"
            //   );
            // }
          }
        },
        {
          title: '备注',
          key: 'comment',
          align: "center",
        },
        {
          title: '市场',
          key: 'marketName',
          align: "center"
        },
        {
          title: '时间',
          key: '',
          align: "center",
          render: function (h, params) {
            return h('div',
              [
                h('span', {
                }, params.row.addDate.slice(5,16)),
              ]
            );

          }
        }
      ],//行情价格表格title
      data2: [],//行情价格表格表格数据
      page:1,
      total:0
    }
  },
  components: {
    NavHead,
    LogoBar
  },
  computed: {
    defaultOption () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created () {
    this.productId=this.$route.query.productId;
    this.marketId=this.$route.query.marketId;
    this.token = this.$store.state.token
    this.setVip=localStorage.getItem('setVip')
    this.userName = localStorage.getItem('userName')
    this.selectMpDetail()
    this.getLastWeek()
    this.getAuctionNewsfx_rmfx();
    this.selectMarketPriceHistory()
  },
  // mounted(){
  //   this.drawLine();
  // },
  methods: {
    changes(index){
      this.current = index;
    },
    // 选择日期触发
    mate_date_Change(e) {
      this.page=1
      this.current=3;
      this.starttime = e[0];
      this.endtime = e[1];
      this.selectMarketPriceHistory()
      this.selectMpHistoryChart()
    },
    // 近3个月
    getLast3Month() {
      this.current=2;
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;//0-11表示1-12月
      var day = now.getDate();
      var dateObj = {};
      dateObj.now = year + '-' + month + '-' + day;
      var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
      if(month - 3 <= 0){ //如果是1、2、3月，年数往前推一年
        var last3MonthDay = new Date((year - 1), (12 - (3 - parseInt(month))), 0).getDate();    //3个月前所在月的总天数
        if(last3MonthDay < day){    //3个月前所在月的总天数小于现在的天日期
          dateObj.last = (year - 1) + '-' + (12 - (3 - month)) + '-' + last3MonthDay;
        }else{
          dateObj.last = (year - 1) + '-' + (12 - (3 - month)) + '-' + day;
        }
      }else{
        var last3MonthDay = new Date(year, (parseInt(month) - 3), 0).getDate();    //3个月前所在月的总天数
        if(last3MonthDay < day){    //3个月前所在月的总天数小于现在的天日期
          if(day < nowMonthDay){        //当前天日期小于当前月总天数,2月份比较特殊的月份
            dateObj.last = year + '-' + (month - 3) + '-' + (last3MonthDay - (nowMonthDay - day));
          }else{
            dateObj.last = year + '-' + (month - 3) + '-' + last3MonthDay;
          }
        }else{
          dateObj.last = year + '-' + (month - 3) + '-' + day;
        }
      }
      this.starttime=dateObj.last
      this.endtime=dateObj.now
      this.page=1
      this.selectMarketPriceHistory()
      this.selectMpHistoryChart()
    },
  // 近一个月
    getLastMonth() {
      this.current=1;
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;//0-11表示1-12月
        var day = now.getDate();
        var dateObj = {};
        dateObj.now = year + '-' + month + '-' + day;
        var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
        if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
          dateObj.last = (year - 1) + '-' + 12 + '-' + day;
        }else{
          var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
          if(lastMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
            if(day < nowMonthDay){        //当前天日期小于当前月总天数
              dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
            }else{
              dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
            }
          }else{
            dateObj.last = year + '-' + (month - 1) + '-' + day;
          }
        }
      this.starttime=dateObj.last
      this.endtime=dateObj.now
      this.page=1
      this.selectMarketPriceHistory()
      this.selectMpHistoryChart()
    },
  // 近一周
     getLastWeek() {
      this.current=0;
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth() + 1;//0-11表示1-12月
        var day = now.getDate();
        var dateObj = {};
        dateObj.now = year + '-' + month + '-' + day;
        if(day - 7 <= 0){   //如果在当月7日之前
          var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();    //1周前所在月的总天数
          if(month - 1 <= 0){ //如果在当年的1月份
            dateObj.last = (year - 1) + '-' + 12 + '-' + (31 - (7 - day));
          }else{
            dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (7 - day));
          }
        }else{
          dateObj.last = year + '-' + month + '-' + (day -7);
        }
       this.starttime=dateObj.last
       this.endtime=dateObj.now
       this.page=1
       this.selectMarketPriceHistory()
       this.selectMpHistoryChart()
      },
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: ''
        },
        color:['#E83033','#31AA24'],
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '1%',
          top:'10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data:this.axisarr.data.reverse(),
          axisLabel: {
            textStyle: {
              color: '#000',
              fontSize:'12'
            },
            showMaxLabel: true
          },
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisLabel: {
            textStyle: {
              color: '#000',
              fontSize:'12'
            },
          },
          splitLine: {
            show: true,
            lineStyle:{
              color: ['#F5F5F5'],
              width: 1,
              type: 'solid'
            }
          }
        },
        series: [
          {
            name:'最高',
            type:'line',
            //数据堆叠，同个类目轴上系列配置相同的stack值可以堆叠放置
            // stack: '总量',
            data:this.axisarr.leftarr.reverse()
          },
          {
            name:'最低',
            type:'line',
            //数据堆叠，同个类目轴上系列配置相同的stack值可以堆叠放置
            // stack: '总量',
            data:this.axisarr.rightarr.reverse()
          }
        ]
      });
    },
    changenext(page){
      this.page=page
      this.selectMarketPriceHistory()
    },
    // 获取表格信息
    selectMarketPriceHistory(){
      let data = {
        productId: this.productId,
        beginTime: this.starttime,
        endTime:this.endtime,
        // beginTime:"2021-03-03",
        // endTime:"2021-03-13",
        pageNum:this.page,
        pageSize:10
      }
      selectMarketPriceHistoryApi(data).then((res) => {
        if(res.code==0){
          this.data2=res.data.list
          this.total=res.data.total
          this.loading=false
        }else{

        }
      })
    },
    // 获取柱状图数据
    selectMpHistoryChart(){
      let data = {
        productId: this.productId,
        beginTime: this.starttime,
        endTime:this.endtime,
        // beginTime:"2021-03-03",
        // endTime:"2021-03-13",
      }
      selectMpHistoryChartApi(data).then((res) => {
        if(res.Code==0){
            this.axisarr.leftarr=res.hPrice
            this.axisarr.rightarr=res.lPrice
            this.axisarr.data=res.aDate
            this.hPriceSum=res.hPriceSum
            this.lPriceSum=res.lPriceSum
            this.aPriceSum=res.aPriceSum
            this.drawLine();
        }else{

        }
      })
    },
    // 关注数据
    selectMpDetail(){
      let data = {
        productId: this.productId
      }
      selectMpDetailApi(data).then((res) => {
        if(res.code==0){
          this.nametop.comment=res.data.comment
          this.nametop.groupName=res.data.groupName
          this.nametop.marketName=res.data.marketName
          this.nametop.parentName=res.data.parentName
          this.nametop.productName=res.data.productName
          this.nametop.hprice=res.data.hprice
          this.nametop.lprice=res.data.lprice
          this.nametop.addDate=res.data.addDate
          this.nametop.data1=res.data1
            if(res.data1==1){
              this.nametop.type=1
            }else{
              this.nametop.type=0
            }
        }
      })
    },
    focusOrCancelPro(type){
      let data = {
        productId: this.productId,
        marketId:this.marketId,
        type:type
      }
      focusOrCancelProApi(data).then((res) => {
        if(res.code==0){
            if(type==1){
              // this.nametop.type==0
              this.nametop.data1=0
            }else{
              // this.nametop.type==1
              this.nametop.data1=1
            }
        this.$Message.success(res.errInfo)
        }else{
        this.$Message.warning(res.errInfo)
      }
    })
    },
    handleImgClick(e) {
      let id = e.target.id;
      let page = e.target.dataset.page;
      let subjectId = e.target.dataset.subjectid;
      let marketId = e.target.dataset.marketid;
      let data = {
        num:1,
        size:1,
        productId:14953,
        userName:this.userName,
        id:e.target.id
        // name:1
      }
      getAuctionNewsApi(data).then((res) => {
        if(res.data&&res.data.length>0){
        this.$router.push({
          name: 'analysisDel',
          query: {
            id: id,
            page:page,
            subjectId:subjectId,
            marketId:marketId
          },
        })
      }else{
        this.$Modal.warning({
          title: '提示',
          content:
            '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
        })
      }
    })
    },
    getAuctionNewsfx_rmfx(){
      let data = {
        num:15,
        size:10,
        productId:14953,
        // name:1
      }
      getAuctionNewsApi(data).then((res) => {
        if(res.code==0){
        this.fgtjarrs=res.data
      }else{
        this.$Message.warning(res.errInfo)
      }
    })
    },
  }
}
</script>

<style scoped>
 .mbxtit{
   width: 100%;
   height: 40px;
   background:#E6EBF5;
   font-size: 14px;
   line-height: 40px;
 }
  .flistleft{
    width: 800px;
    float: left;
  }
 .flistleft .allbody{
   position: relative;
   border: 1px solid #E6EBF5;
   margin: 30px 0;
   font-size: 14px;
   padding: 20px;
 }
 .flistleft .leftbody{
   float: left;
   width: 500px;
 }
 .flistleft .leftbody span{
   display: inline-block;
 }
 .flistleft .leftbody p:nth-child(1){
   color: #25449A;
 }
 .flistleft .leftbody p:nth-child(1) span:nth-child(1){
    background: #DCEDFF;
   line-height: 20px;
   padding: 0 4px;
   border-radius: 3px;
   margin-right: 7px;
 }
 .flistleft .leftbody p:nth-child(1) span:nth-child(2){
   /* font-size: 16px; */
   font-weight: bold;
   transform: scale(1.15);
   margin-left: 10px;
 }
 .flistleft .leftbody p:nth-child(2){
   line-height: 48px;
   font-size: 16px;
   margin-top: 8px;
 }
 .flistleft .leftbody p:nth-child(2) span:nth-child(1){
   margin-right: 45px;
 }
 .flistleft .leftbody p:nth-child(3) {
   margin-top: -5px;
   font-size: 16px;
   margin-bottom: 12px;
 }
 .flistleft .rightbody{
   position: absolute;
   right: 20px;
   bottom: 20px;
 }
 .flistleft .rightbody span{
   display: block;
   text-align: center;
   font-size: 14px;
 }
 .flistleft .rightbody span:nth-child(2){
   line-height: 68px;
   font-size: 30px;
   font-weight: bold;
 }
 .flistleft .rightbody span:nth-child(3){
   width: 80px;
   height: 32px;
   text-align: center;
   line-height: 32px;
   border: 1px solid #25449A;
   border-radius: 3px;
   margin: 0 auto;
   cursor: pointer;
   color: #25449A;
   font-size: 14px;
 }
 .flistleft .rightbody span:nth-child(3).active{
   background:#25449A ;
  color: #fff;
 }
  .flistright{
    width: 350px;
    float: right;
  }
.flistright .righttop{
background: #25449A;
  border-radius: 5px;
  margin-top: 38px;
  overflow: hidden;
  }
.flistright .righttop p{
  text-align: center;
  line-height: 84px;
  font-size: 18px;
  color: #fff;
}
.flistright .righttop span{
  display: block;
  font-size: 18px;
  color: #303133;
  line-height: 60px;
  text-align: center;
  background: #D9EBFF;
  margin-top: 30px;
}
.flistright .rightbottom{
  margin-top: 23px;
}
.flistright .rightbottom p{
  line-height: 40px;
  background: #E6EBF5;
  border-left: 3px solid #25449A;
  font-size: 15px;
  padding-left: 18px;
}
.tablebottom .timechose{
border-bottom: 1px solid #E6EBF5;
  cursor: pointer;
}
 .tablebottom  .timeself{
  float: right;
}
 .tablebottom .timechose ul{
   float: left;
 }
 .tablebottom .timechose ul li{
   width: 66px;
   float: left;
   /* font-size: 16px; */
   text-align: center;
   line-height: 46px;
 }
 .tablebottom .timechose ul li span{
   display: inline-block;
   transform: scale(1.3);
   font-weight: 700;
 }
 .tablebottom .timechose ul li.active{
   position: relative;
   top: 1px;
   color: #25449A;
   border-bottom: 2px solid #25449A;
 }
 .tablebottom .pricesee{
   font-size: 14px;
   line-height:48px;
   background: #E6EBF5;
   margin-top: 40px;
 }
 .tablebottom .pricesee span{
   font-size: 18px;
 }
 .tablebottom .pricesee li{
   float: left;
   margin: 0 42px;
 }
 .tablebottom .line{
   border: 1px solid #E6EBF5;
 }
 .tiaojiabody{
   height: 380px;
   padding: 0px 8px;
   font-size: 14px;
   overflow: hidden;
 }
 .tiaojiabody li{
   line-height: 38px;
   cursor: pointer;
   width: 300px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   list-style: disc;
 }
 .tiaojiabody li:hover{
   color: #25449A;
   text-decoration: underline;
 }
 .tiaojiabody li span.fl:before {
   content: ".";
   width: 4px;
   height: 4px;
   display: inline-block;
   border-radius: 50%;
   background: #515a6e;
   vertical-align: middle;
   margin-right: 15px;
 }
</style>
<style>
  .tablesj .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td, .tablesj  .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td{
    background-color:#F4F6FB
  }
  .ivu-table-cell .up{
    display:inline-block;
    width:10px;
    height:13px;
    background: url('~assets/img/up.png');
    vertical-align: middle;
    margin-right: 3px;
  }
  .ivu-table-cell .down{
    display:inline-block;
    width:10px;
    height:13px;
    background: url('~assets/img/down.png');
    vertical-align: middle;
    margin-right: 3px;
  }
  .ivu-table-cell .red{
    color:#D31818 ;
  }
  .ivu-table-cell .green{
    color:#31AA24 ;
  }
</style>
